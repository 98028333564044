<template>
    <div class="dashboard-block mt-0">
        <h3 class="block-title">Categorias</h3>
        <div class="inner-block">
            <div class="post-ad-tab">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-item-info-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-item-info" type="button" role="tab" aria-controls="nav-item-info"
                            aria-selected="true">
                            <span class="serial">01</span>
                            Crear
                            <span class="sub-title">Crear categorias</span>
                        </button>
                        <button class="nav-link" id="nav-item-details-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-item-details" type="button" role="tab" aria-controls="nav-item-details"
                            aria-selected="false">
                            <span class="serial">02</span>
                            Ver
                            <span class="sub-title">Ver categorias</span>
                        </button>
                        <button class="nav-link" id="nav-user-info-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-user-info" type="button" role="tab" aria-controls="nav-user-info"
                            :disabled="selectedCategoryId == null">
                            <span class="serial">03</span>
                            Editar
                            <span class="sub-title">Editar categoria</span>
                        </button>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <crearView />
                    <verView @edit-category="editCategory" />
                    <editarView v-if="selectedCategoryId" :categoryId="selectedCategoryId" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import crearView from './crear.vue';
import verView from './ver.vue';
import editarView from './editar.vue';

export default {
    name: "templateCategoria",
    components: {
        crearView,
        verView,
        editarView
    },
    data() {
        return {
            selectedCategoryId: null
        };
    },
    methods: {
        editCategory(id) {
            this.selectedCategoryId = id;
            console.log(id)
            this.$nextTick(() => {
                document.getElementById('nav-user-info-tab').click(); // Cambia a la pestaña de edición

            });
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>
