<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="handleSubmit">
                <div class="row">
                    <!-- Campos para ambos servicios -->
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nombre del Servicio</label>
                            <input v-model="serviceName" type="text" placeholder="Enter nombre" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción del Servicio</label>
                            <textarea v-model="serviceDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>

                    <!-- Campos específicos para Contenido de Servicio -->

                    <div class="col-12">
                        <div class="form-group">
                            <label>Nota del Contenido</label>
                            <textarea v-model="nota" placeholder="Enter nota"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Documentos</label>
                            <ul>
                                <li v-for="(doc, index) in documentos" :key="index">
                                    <i class="lni lni-chevron-right"></i> {{ doc }}
                                    <button type="button" @click="removeDocumento(index)">Eliminar</button>
                                </li>
                            </ul>
                            <div>
                                <input v-model="newDocumento" type="text" placeholder="Enter document name">
                                <button type="button" @click="addDocumento">Agregar Documento</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Precio del Servicio</label>
                            <input v-model="servicePrice" type="number" placeholder="Enter Price" required>
                        </div>
                        <div class="form-group col-6">
                            <label>URL del Servicio</label>
                            <input v-model="serviceUrl" type="text" placeholder="Enter URL" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Categoría del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="serviceCategory" class="user-chosen-select" required>
                                    <option value="" disabled selected>Selecciona una categoría</option>
                                    <option v-for="category in categories" :key="category.id" :value="category.id">
                                        {{ category.nombreCategoria }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Status del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="serviceStatus" class="user-chosen-select" required>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Visibilidad del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="servicePublico" class="user-chosen-select" required>
                                    <option value="1">Público</option>
                                    <option value="0">Oculto</option>
                                </select>
                            </div>
                        </div>
                    </div>



                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Crear</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearUnificado',
    data() {
        return {
            // Campos comunes
            serviceName: '',
            serviceDescription: '',
            servicePrice: '',
            serviceUrl: '',
            serviceCategory: '',
            serviceStatus: '',
            servicePublico: '',
            categories: [],

            // Campos específicos de contenido servicio

            nota: '',
            documentos: [],
            newDocumento: '',

            message: '',
            messageClass: ''
        };
    },
    created() {
        this.fetchCategories();
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async handleSubmit() {
            try {
                // Enviar los datos al endpoint de Servicio
                // Enviar los datos al endpoint de Servicio y capturar el ID del servicio creado
                const response = await axios.post('/api/servicio/create', {
                    nombre: this.serviceName,
                    descripcion: this.serviceDescription,
                    precio: this.servicePrice,
                    url: this.serviceUrl,
                    categoria_id: this.serviceCategory,
                    status: parseInt(this.serviceStatus),
                    publico: parseInt(this.servicePublico)
                });

                // Captura el ID del servicio recién creado
                const serviceId = response.data.id;
                // Enviar los datos al endpoint de Contenido Servicio
                await axios.post('/api/contenido_servicio/create', {

                    nota: this.nota,
                    documentos: JSON.stringify(this.documentos),
                    servicio_id: serviceId // Asegúrate de tener el ID del servicio al que pertenece este contenido
                });

                this.message = '¡Creación exitosa!';
                this.messageClass = 'alert alert-success';

                this.resetForm();
            } catch (error) {
                console.error(error);
                this.message = 'Error al crear el servicio/contenido';
                this.messageClass = 'alert alert-danger';
            }
        },
        resetForm() {
            this.serviceName = '';
            this.serviceDescription = '';
            this.servicePrice = '';
            this.serviceUrl = '';
            this.serviceCategory = '';
            this.serviceStatus = '';
            this.servicePublico = '';

            // Reset campos de contenido servicio

            this.nota = '';
            this.documentos = [];
            this.newDocumento = '';
        },
        addDocumento() {
            if (this.newDocumento) {
                this.documentos.push(this.newDocumento);
                this.newDocumento = '';
            }
        },
        removeDocumento(index) {
            this.documentos.splice(index, 1);
        }
    }
};
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
</style>
