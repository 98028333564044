<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="createContenidoServicio">
                <div class="row">
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Categoría</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="selectedCategory" class="user-chosen-select" @change="filterServicios"
                                    required>
                                    <option value="" disabled selected>Selecciona una categoría</option>
                                    <option value="all">Todas las categorías</option>
                                    <option v-for="category in categories" :key="category.id" :value="category.id">
                                        {{ category.nombreCategoria }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="servicioId" class="user-chosen-select" required>
                                    <option value="" disabled selected>Selecciona un servicio</option>
                                    <option v-for="servicio in filteredServicios" :key="servicio.id"
                                        :value="servicio.id">
                                        {{ servicio.nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Título del Contenido</label>
                            <input v-model="titulo" type="text" placeholder="Enter título" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción del Contenido</label>
                            <textarea v-model="descripcion" placeholder="Enter descripción"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nota del Contenido</label>
                            <textarea v-model="nota" placeholder="Enter nota"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Documentos</label>
                            <ul>
                                <li v-for="(doc, index) in documentos" :key="index">
                                    <i class="lni lni-chevron-right"></i> {{ doc }}
                                    <button type="button" @click="removeDocumento(index)">Eliminar</button>
                                </li>
                            </ul>
                            <div>
                                <input v-model="newDocumento" type="text" placeholder="Enter document name">
                                <button type="button" @click="addDocumento">Agregar Documento</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Crear Contenido de Servicio</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearContenidoServicio',
    data() {
        return {
            titulo: '',
            descripcion: '',
            nota: '',
            documentos: [],
            newDocumento: '',
            servicioId: '',
            servicios: [],
            filteredServicios: [],
            categories: [],
            selectedCategory: 'all',
            message: '',
            messageClass: ''
        }
    },
    created() {
        this.fetchCategorias();
        this.fetchServicios();
    },
    methods: {
        async fetchCategorias() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchServicios() {
            try {
                const response = await axios.get('/api/servicio/all');
                this.servicios = response.data;
                this.filterServicios();
            } catch (error) {
                console.error('Error fetching servicios:', error);
            }
        },
        filterServicios() {
            if (this.selectedCategory === 'all') {
                this.filteredServicios = this.servicios;
            } else {
                this.filteredServicios = this.servicios.filter(servicio => servicio.categoria_id === parseInt(this.selectedCategory));
            }
        },
        addDocumento() {
            if (this.newDocumento) {
                this.documentos.push(this.newDocumento);
                this.newDocumento = '';
            }
        },
        removeDocumento(index) {
            this.documentos.splice(index, 1);
        },
        async createContenidoServicio() {
            try {
                await axios.post('/api/contenido_servicio/create', {
                    titulo: this.titulo,
                    descripcion: this.descripcion,
                    nota: this.nota,
                    documentos: JSON.stringify(this.documentos),
                    servicio_id: this.servicioId,
                });
                this.message = 'Contenido de servicio creado exitosamente!';
                this.messageClass = 'alert alert-success';

                this.resetForm();
            } catch (error) {
                console.error(error);
                this.message = 'Error al crear el contenido de servicio';
                this.messageClass = 'alert alert-danger';
            }
        },
        resetForm() {
            this.titulo = '';
            this.descripcion = '';
            this.nota = '';
            this.documentos = [];
            this.newDocumento = '';
            this.servicioId = '';
            this.selectedCategory = 'all';
            this.filterServicios();
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
</style>
