

<template>
    <div>
      <h1>About</h1>
      <p>Esta es la página de About.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutView'
  }
  </script>
  