<template>
    <div class="col-lg-2 col-md-4 col-12" style="padding-right:0px;">
        <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark vh-100">
            <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <img src="https://www.avaconsultores.com.mx/public/images/logoFooter.png" width="250px" class="p-2"
                    alt="Logo Ava Consultores">
            </a>

            <ul class="nav nav-pills flex-column mb-auto pt-3">
                <p><small>Opciones de la página</small></p>
                <hr class="mt-2">

                <li class="nav-item">
                    <a href="#" class="nav-link active rounded collapsed" data-bs-toggle="collapse"
                        data-bs-target="#categoria-collapse" aria-expanded="false"
                        @click="selectProjectType('ava_consultores')">
                        <i class="lni lni-dashboard me-2"></i>
                        Contenido Ava consultores
                    </a>
                    <div class="collapse" id="categoria-collapse" style="padding-left: 40px;">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li><button @click="navigateWithProjectType('/categoria', 'ava_consultores')"
                                    class=" nav-link text-white rounded">Categorias</button></li>
                            <li><button @click="navigateWithProjectType('/servicio', 'ava_consultores')"
                                    class=" nav-link text-white rounded">Servicios</button></li>
                            <li><button @click="navigateWithProjectType('/contenido-servicio', 'ava_consultores')"
                                    class=" nav-link text-white rounded">Contenido servicios</button></li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item">
                    <a href="#" class="nav-link text-white rounded collapsed" data-bs-toggle="collapse"
                        data-bs-target="#programa-collapse" aria-expanded="false"
                        @click="selectProjectType('programa_interno')">
                        <i class="lni lni-dashboard me-2"></i>
                        Contenido Programa interno
                    </a>
                    <div class="collapse" id="programa-collapse" style="padding-left: 40px;">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li><button @click="navigateWithProjectType('/categoria', 'programa_interno')"
                                    class=" nav-link text-white rounded">Categorias</button></li>
                            <li><button @click="navigateWithProjectType('/servicio', 'programa_interno')"
                                    class=" nav-link text-white rounded">Servicios</button></li>
                            <li><button @click="navigateWithProjectType('/contenido-servicio', 'programa_interno')"
                                    class=" nav-link text-white rounded">Contenido servicios</button></li>
                        </ul>
                    </div>
                </li>

                <li>
                    <a href="#" class="nav-link text-white">
                        <svg class="bi me-2" width="16" height="16">
                            <use xlink:href="#speedometer2"></use>
                        </svg>
                        Dashboard
                    </a>
                </li>
            </ul>

            <hr>
            <div class="dropdown">
                <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                    id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="User avatar" width="32" height="32"
                        class="rounded-circle me-2">
                    <strong>mdo</strong>
                </a>
                <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                    <li><a class="dropdown-item" href="#">New project...</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#">Sign out</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: 'SidebarView',
    setup(props, { emit }) {
        const router = useRouter();
        const projectType = ref('ava_consultores');

        const selectProjectType = (type) => {
            projectType.value = type;
            emit('update:projectType', type);
        };

        const navigateWithProjectType = (path, type) => {
            projectType.value = type;
            emit('update:projectType', type);
            router.push({ path, query: { projectType: type } });
        };

        return {
            selectProjectType,
            navigateWithProjectType
        };
    }
};
</script>

<style scoped>
.sidebar {
    width: 200px;
    background-color: #f4f4f4;
    border-right: 1px solid #ccc;
}

.btn-toggle-nav .nav-link:hover {
    color: #0d6efd !important;
}

.dashboard-menu a:hover {
    cursor: pointer;
}
</style>
