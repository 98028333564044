<template>
    <div class="tab-pane fade show active" id="nav-user-info" role="tabpanel" aria-labelledby="nav-user-info-tab">
        <div class="step-one-content">
            <div v-if="message" :class="messageClass">{{ message }}</div>
            <form class="default-form-style" @submit.prevent="updateService">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>canonical_url</label>
                            <input v-model="canonical_url" type="text" placeholder="Enter nombre" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Meta Title</label>
                            <input v-model="metaTitle" type="text" placeholder="Enter nombre" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Meta descripción</label>
                            <textarea v-model="metaDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Meta KeyWords</label>
                            <textarea v-model="metaKeyWords" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Meta slug</label>
                            <input v-model="metaSlug" placeholder="Enter Description" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Actualizar Servicio</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'EditarServicio',
    props: {
        serviceId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            canonical_url: '',
            metaDescription: '',
            metaTitle: '',
            metaKeyWords: "",
            metaSlug: "",

            message: '',
            messageClass: ''
        }
    },
    created() {
        this.fetchCategories();
        this.fetchService();
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchService() {
            try {
                const response = await axios.get(`/api/seo/find/${this.serviceId}`);
                const seo = response.data;
                this.canonical_url = seo.canonical_url;
                this.metaDescription = seo.description;
                this.metaTitle = seo.title
                this.metaSlug = seo.page_slug;
                this.metaKeyWords = seo.keywords


            } catch (error) {
                console.error('Error fetching service:', error);
                alert('Error al cargar el servicio');
            }
        },
        async updateService() {
            try {
                await axios.put(`/api/seo/update/${this.serviceId}`, {
                    canonical_url: this.canonical_url,
                    description: this.metaDescription,
                    keywords: this.metaKeyWords,
                    page_slug: this.metaSlug,
                    title: this.metaTitle
                });
                this.message = 'Servicio actualizado exitosamente!';
                this.messageClass = 'alert alert-success';
            } catch (error) {
                console.error('Error updating service:', error);
                this.message = 'Error al actualizar el servicio';
                this.messageClass = 'alert alert-danger';
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchService();
        });
    },
    watch: {
        serviceId: {
            handler() {
                this.fetchService();
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>
