<template>
    <div class="tab-pane fade show active" id="nav-user-info" role="tabpanel" aria-labelledby="nav-user-info-tab">
        <div class="step-one-content">
            <div v-if="message" :class="messageClass">{{ message }}</div>
            <form class="default-form-style" @submit.prevent="updateService">
                <div class="row">
                    <!-- Campos para ambos servicios -->
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nombre del Servicio</label>
                            <input v-model="serviceName" type="text" placeholder="Enter nombre" required>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción del Servicio</label>
                            <textarea v-model="serviceDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Precio del Servicio</label>
                            <input v-model="servicePrice" type="number" placeholder="Enter Price" required>
                        </div>
                        <div class="form-group col-6">
                            <label>URL del Servicio</label>
                            <input v-model="serviceUrl" type="text" placeholder="Enter URL" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Categoría del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="serviceCategory" class="user-chosen-select" required>
                                    <option value="" disabled selected>Selecciona una categoría</option>
                                    <option v-for="category in categories" :key="category.id" :value="category.id">
                                        {{ category.nombreCategoria }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Status del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="serviceStatus" class="user-chosen-select" required>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Visibilidad del Servicio</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="servicePublico" class="user-chosen-select" required>
                                    <option value="1">Público</option>
                                    <option value="0">Oculto</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Imagen del Servicio</label>
                            <input v-model="serviceImage" type="text" placeholder="Enter Image URL">
                        </div>
                    </div>
                    <!-- Campos específicos para Contenido del Servicio -->
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nota del Contenido</label>
                            <textarea v-model="nota" placeholder="Enter nota"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Documentos</label>
                            <ul>
                                <li v-for="(doc, index) in documentos" :key="index">
                                    <i class="lni lni-chevron-right"></i> {{ doc }}
                                    <button type="button" @click="removeDocumento(index)">Eliminar</button>
                                </li>
                            </ul>
                            <div>
                                <input v-model="newDocumento" type="text" placeholder="Enter document name">
                                <button type="button" @click="addDocumento">Agregar Documento</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Actualizar Servicio</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'EditarServicio',
    props: {
        serviceId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            serviceName: '',
            serviceDescription: '',
            servicePrice: '',
            serviceUrl: '',
            serviceCategory: '',
            serviceStatus: '',
            servicePublico: '',
            serviceImage: '',
            categories: [],
            message: '',
            messageClass: '',
            // Campos específicos de contenido servicio
            nota: '',
            documentos: [],
            newDocumento: ''
        }
    },
    created() {
        this.fetchCategories();
        this.fetchService();
        this.fetchContenidoServicio();
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchService() {
            try {
                const response = await axios.get(`/api/servicio/find/${this.serviceId}`);
                const service = response.data;
                this.serviceName = service.nombre;
                this.serviceDescription = service.descripcion;
                this.servicePrice = service.precio;
                this.serviceUrl = service.url;
                this.serviceCategory = service.categoria_id;
                this.serviceStatus = service.status;
                this.servicePublico = service.publico;
                this.serviceImage = service.img;
            } catch (error) {
                console.error('Error fetching service:', error);
                alert('Error al cargar el servicio');
            }
        },
        async fetchContenidoServicio() {
            try {
                const response = await axios.get(`/api/contenido_servicio/find/${this.serviceId}`);
                const contenido = response.data;
                this.nota = contenido.nota;
                this.documentos = JSON.parse(contenido.documentos);
            } catch (error) {
                console.error('Error fetching contenido servicio:', error);
            }
        },
        async updateService() {
            try {
                // Actualizar el Servicio
                await axios.put(`/api/servicio/update/${this.serviceId}`, {
                    nombre: this.serviceName,
                    descripcion: this.serviceDescription,
                    precio: this.servicePrice,
                    url: this.serviceUrl,
                    categoria_id: this.serviceCategory,
                    status: parseInt(this.serviceStatus),
                    publico: parseInt(this.servicePublico),
                    img: this.serviceImage
                });

                // Actualizar el Contenido del Servicio
                await axios.put(`/api/contenido_servicio/update/${this.serviceId}`, {
                    nota: this.nota,
                    documentos: JSON.stringify(this.documentos)
                });

                this.message = '¡Servicio y contenido actualizados exitosamente!';
                this.messageClass = 'alert alert-success';
            } catch (error) {
                console.error('Error updating service or content:', error);
                this.message = 'Error al actualizar el servicio o el contenido';
                this.messageClass = 'alert alert-danger';
            }
        },
        addDocumento() {
            if (this.newDocumento) {
                this.documentos.push(this.newDocumento);
                this.newDocumento = '';
            }
        },
        removeDocumento(index) {
            this.documentos.splice(index, 1);
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchService();
            vm.fetchContenidoServicio();
        });
    },
    watch: {
        serviceId: {
            handler() {
                this.fetchService();
                this.fetchContenidoServicio();
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}

.alert {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
</style>
