<template>
    <section class="login section vh-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
            <div class="form-head">
              <h4 class="title">Login</h4>
              <form @submit.prevent="login">
                <div class="form-group">
                  <label>Username or email</label>
                  <input name="username" type="text" v-model="username" class="form-control">
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input name="password" type="password" v-model="password" class="form-control">
                </div>
                <div class="check-and-pass">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input width-auto" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Remember me</label>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                      <a href="javascript:void(0)" class="lost-pass">Lost your password?</a>
                    </div>
                  </div>
                </div>
                <div class="button">
                  <button type="submit" class="btn">Login Now</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
import axios from '../axios'; // Asegúrate de importar tu configuración de axios
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
  login() {
    axios.get('/sanctum/csrf-cookie', {
      withCredentials: true
    })
    .then(() => {
      return axios.post('/api/login', {
        userName: this.username,
        password: this.password
      }, {
        withCredentials: true
      });
    })
    .then(response => {
      console.log(response.data);
      localStorage.setItem('tokenAccess', response.data.access_token); // Almacena la clave API en el localStorage
        this.$router.push('/'); // Redirige a la página de inicio
    })
    .catch(error => {
      console.error('Error during login:', error);
    });
  }
}
,
  name:"LoginView"
}
  </script>
  
  <style scoped>
  /* Puedes agregar aquí los estilos específicos para este componente */
  </style>
  