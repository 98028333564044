<template>
    <div class="tab-pane fade show active" id="nav-user-info" role="tabpanel" aria-labelledby="nav-user-info-tab">
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="updateCategory">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nombre categoría</label>
                            <input v-model="categoryName" type="text" placeholder="Enter Title" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Url de la categoría</label>
                            <input v-model="categoryUrl" type="text" placeholder="Enter URL" required>
                        </div>
                        <div class="form-group col-6">
                            <label>Imagen de la categoría</label>
                            <input v-model="categoryImage" type="text" placeholder="Enter Image URL" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Status sistema</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="status" class="user-chosen-select">
                                    <option value="activo">Activo</option>
                                    <option value="desactivado">Desactivado</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Publico</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="publico" class="user-chosen-select">
                                    <option value="1">Publico</option>
                                    <option value="0">Oculto</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea v-model="categoryDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Actualizar Categoría</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'EditarCategoria',
    props: {
        categoryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            categoryName: '',
            categoryUrl: '',
            categoryImage: '',
            categoryDescription: '',
            status: '',
            publico: ''
        }
    },
    methods: {
        async fetchCategory() {
            try {
                const response = await axios.get(`/api/categoria/find/${this.categoryId}`);
                const category = response.data;
                this.categoryName = category.nombreCategoria;
                this.categoryUrl = category.url;
                this.categoryImage = category.img;
                this.categoryDescription = category.descripcionCategoria;
                this.status = category.status;
                this.publico = category.publico;
            } catch (error) {
                console.error('Error fetching category:', error);
                alert('Error al cargar la categoría');
            }
        },
        async updateCategory() {
            try {
                await axios.put(`/api/categoria/update/${this.categoryId}`, {
                    nombreCategoria: this.categoryName,
                    url: this.categoryUrl,
                    img: this.categoryImage,
                    descripcionCategoria: this.categoryDescription,
                    status: this.status,
                    publico: this.publico
                });
                alert('Categoría actualizada exitosamente!');
            } catch (error) {
                console.error('Error updating category:', error);
                alert('Error al actualizar la categoría');
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchCategory();
        });
    },
    watch: {
        categoryId: {
            handler() {
                this.fetchCategory();
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>
