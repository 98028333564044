<template>
    <div class="tab-pane fade active show" id="nav-item-info" role="tabpanel" aria-labelledby="nav-item-info-tab">
        <div class="step-one-content">
            <form class="default-form-style" @submit.prevent="createCategory">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Nombre categoría</label>
                            <input v-model="categoryName" type="text" placeholder="Enter Title" required>
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Url de la categoría</label>
                            <input v-model="categoryUrl" type="text" placeholder="Enter URL" required>
                        </div>
                        <div class="form-group col-6">
                            <label>Imagen de la categoría</label>
                            <input ref="categoryImage" type="file" @change="handleFileUpload">
                        </div>
                    </div>
                    <div class="col-12 row">
                        <div class="form-group col-6">
                            <label>Status sistema</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="status" class="user-chosen-select">
                                    <option value="activo">Activo</option>
                                    <option value="desactivado">Desactivado</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Publico</label>
                            <div class="selector-head">
                                <span class="arrow"><i class="lni lni-chevron-down"></i></span>
                                <select v-model="publico" class="user-chosen-select">
                                    <option value="1">Publico</option>
                                    <option value="0">Oculto</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea v-model="categoryDescription" placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group button mb-0">
                            <button type="submit" class="btn">Crear Categoría</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CrearCategoria',
    data() {
        return {
            categoryName: '',
            categoryUrl: '',
            categoryImage: null,
            categoryDescription: '',
            status: '',
            publico: '',
        }
    },
    methods: {
        handleFileUpload(event) {
            this.categoryImage = event.target.files[0];
        },
        async createCategory() {
            try {
                // Subir imagen al servidor del subdominio
                let formData = new FormData();
                formData.append('image', this.categoryImage);

                const imageResponse = await axios.post('/api/upload-image/categories', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


                const imageUrl = imageResponse.data.imageUrl;


                // Crear categoría en la API principal
                const response = await axios.post('/api/categoria/create', {
                    nombreCategoria: this.categoryName,
                    url: this.categoryUrl,
                    img: imageUrl,
                    descripcionCategoria: this.categoryDescription,
                    status: this.status,
                    publico: this.publico
                });

                alert('Categoría creada exitosamente!' + response);
                this.resetForm();
            } catch (error) {
                console.error(error);
                alert('Error al crear la categoría');
            }
        },
        resetForm() {
            this.categoryName = '';
            this.categoryUrl = '';
            this.categoryImage = null;
            this.categoryDescription = '';
            this.status = "";
            this.publico = "";
            this.$refs.categoryImage.value = null;
        }
    }
}
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>
