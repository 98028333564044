<!-- src/App.vue -->
<template>
  <div id="app">
    <div class="dashboard">
      <div class="row">
        <SidebarView v-if="showSidebar" @update:projectType="updateProjectType" />
        <ContentView v-if="showSidebar" :projectType="projectType" />
        <router-view v-if="!showSidebar" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarView from './views/components/Sidebar.vue';
import ContentView from './views/components/Content.vue';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  components: {
    SidebarView,
    ContentView
  },
  setup() {
    const route = useRoute();
    const showSidebar = computed(() => route.path !== '/login');
    const projectType = ref('');

    const updateProjectType = (type) => {
      projectType.value = type;
    };

    return {
      showSidebar,
      projectType,
      updateProjectType
    };
  }
};
</script>
