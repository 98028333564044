<template>
    <div class="tab-pane fade show" id="nav-item-details" role="tabpanel" aria-labelledby="nav-item-details-tab">
        <div class="invoice-items default-list-style mt-5">
            <div class="default-list-title">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>Nombre</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>Url</p>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                        <p>Descripción</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-12">
                        <p>Status</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-12 align-right">
                        <p>Action</p>
                    </div>
                </div>
            </div>

            <div v-for="category in categories" :key="category.id" class="single-list">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>{{ category.nombreCategoria }}
                            <span>{{ category.img }}</span>
                        </p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <p>{{ category.url }}</p>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                        <p>{{ category.descripcionCategoria }}</p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-12">
                        <p :class="{ 'paid': category.status === 'activo', 'unpaid': category.status !== 'activo' }">{{
                            category.status }}</p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 align-left">
                        <ul class="action-btn">
                            <li><a href="javascript:void(0)" @click="editCategory(category.id)"><i
                                        class="lni lni-highlight"></i></a></li>
                            <li><a href="javascript:void(0)" @click="deleteCategory(category.id)"><i
                                        class="lni lni-trash"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="pagination left">
                <ul class="pagination-list">
                    <li><a href="javascript:void(0)">1</a></li>
                    <li class="active"><a href="javascript:void(0)">2</a></li>
                    <li><a href="javascript:void(0)">3</a></li>
                    <li><a href="javascript:void(0)">4</a></li>
                    <li><a href="javascript:void(0)"><i class="lni lni-chevron-right"></i></a></li>
                </ul>
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CategoriaVer',
    data() {
        return {
            categories: []
        };
    },
    created() {
        this.fetchCategories();
    },
    methods: {
        async fetchCategories() {
            try {
                const response = await axios.get('/api/categoria/all');
                this.categories = response.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        editCategory(id) {
            this.$emit('edit-category', id);
        },
        async deleteCategory(id) {
            try {
                await axios.delete(`/api/categoria/find/${id}`);
                this.fetchCategories();
                alert('Categoría eliminada exitosamente!');
            } catch (error) {
                console.error('Error deleting category:', error);
                alert('Error al eliminar la categoría');
            }
        }
    }
};
</script>

<style scoped>
.dashboard-block {
    margin-top: 20px;
}
</style>
